import * as React from 'react';
import { useState, useEffect } from 'react';
import * as Constants from '../constants';
import CircularProgress from '@mui/material/CircularProgress';
// Icons
import EvStationRoundedIcon from '@mui/icons-material/EvStationRounded';
import LocalBarRoundedIcon from '@mui/icons-material/LocalBarRounded';
import KingBedRoundedIcon from '@mui/icons-material/KingBedRounded';
import WeekendRoundedIcon from '@mui/icons-material/WeekendRounded';
import CribRoundedIcon from '@mui/icons-material/CribRounded';
import EventSeatRoundedIcon from '@mui/icons-material/EventSeatRounded';
import FilterVintageRoundedIcon from '@mui/icons-material/FilterVintageRounded';
import CakeRoundedIcon from '@mui/icons-material/CakeRounded';
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
// https://mui.com/material-ui/react-stepper/
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';

export default function Concierge(prop) {

const [userSettings, setUserSettings] = useState({});
const [date, setDate] = useState();
const [loader, setLoader] = useState(false);
const [submit, setSubmit] = useState(true);

useEffect(() => {
	fetchProducts()
	fetchRentalProducts()
}, []);

// useEffect(() => {
// 	console.log(userSettings)
// }, [userSettings]);

const fetchProducts = async () => {
	await fetch(Constants.baseUrl+'/php/fetchApi.php', {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			url: '/api/collections/get/shop',
			content: {
				filter: {active:true}
			}
		})
	})
	.then(res => res.json())
	.then(res => {
		let copy = userSettings
		copy.shoppingService = res.entries
		setUserSettings(copy)
	})
	.then(() => {
		fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/get/bread',
				content: {
					filter: {active:true}
				}
			})
		})
		.then(res => res.json())
		.then(res => {
			let copy = userSettings
			res.entries = res.entries.map(item => {
				return {...item, breadOrder: true};
			});
			copy.breakfastService = res.entries
			setUserSettings(copy)
		})
		.then(res => {
			let copy = userSettings
			copy.breakfastService.forEach(el => {
				if(el.concierge === true) {
					let product = {
						product_de: el.title_de,
						product_it: el.title_it,
						product_en: el.title_en,
						picture: el.img,
						price: el.price,
						extraId: el.extraId
					}
					copy.shoppingService.push(product)
				}
			});
			setUserSettings(copy)
		})
	})
	.catch(function() {
		console.error('Fetch error');
	});
};

const fetchRentalProducts = async () => {
	await fetch(Constants.baseUrl+'/php/fetchApi.php', {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			url: '/api/collections/get/rental',
			content: {
				filter: {active:true}
			}
		})
	})
	.then(res => res.json())
	.then(res => {
		let copy = userSettings
		copy.rentalService = res.entries
		setUserSettings(copy)
	})
	.then(res => {
		let dateArr = []
		for (let index = 0; index < userSettings.rentalService.length; index++) {
			dateArr.push(null)
		}
		setDate(dateArr)
	})
	.catch(function() {
		console.error('Fetch error');
	});
};

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';
const Slide = styled(Slider)(({ theme }) => ({
	color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
	height: 2,
	padding: '15px 0',
	'& .MuiSlider-thumb': {
	  height: 28,
	  width: 28,
	  backgroundColor: '#fff',
	  boxShadow: iOSBoxShadow,
	  '&:focus, &:hover, &.Mui-active': {
		boxShadow:
		  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
		// Reset on touch devices, it doesn't add specificity
		'@media (hover: none)': {
		  boxShadow: iOSBoxShadow,
		},
	  },
	},
	'& .MuiSlider-valueLabel': {
	  fontSize: 14,
	  fontWeight: 'normal',
	  top: 0,
	  padding: 0,
	  height: '100%',
	  transform: 'translateY(0%) !important',
	  backgroundColor: 'unset',
	  color: theme.palette.text.primary,
	  '&:before': {
		display: 'none',
	  },
	  '& *': {
		background: 'transparent',
		color: theme.palette.mode === 'dark' ? '#fff' : '#000',
	  },
	},
	'& .MuiSlider-track': {
	  border: 'none',
	},
	'& .MuiSlider-rail': {
	  opacity: 0.5,
	  backgroundColor: '#bfbfbf',
	},
	'& .MuiSlider-mark': {
	  backgroundColor: '#bfbfbf',
	  height: 8,
	  width: 1,
	  '&.MuiSlider-markActive': {
		opacity: 1,
		backgroundColor: 'currentColor',
	  },
	},
  }));

const handleShopping = (event, newValue) => {
	let copy = userSettings
	copy.shoppingService.forEach(el => {
		if(event.target.name === el['product_'+Constants.lang]) {
			if(el.value !== newValue) {
				el.value = newValue
				setUserSettings(copy)
			}
		}
	})
}
const handleBreakfast = (event, newValue) => {
	let copy = userSettings
	copy.breakfastService.forEach(el => {
		if(event.target.name === el['title_'+Constants.lang]) {
			if(el.value !== newValue) {
				el.value = newValue
				setUserSettings(copy)
			}
		}
	})
}

const ShopProducts = (data) => {
	if(data.name === 'breakfastService' && new Date(prop.guestData.roomTypeReservations[0].arrival).getDay() === 6) return '';
	else if(userSettings[data.name] && userSettings[data.name].length) {
		return (
			<div className='shop-products' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
				{userSettings[data.name].map((product,i) => (
					<Card key={i} sx={{ margin: '12px 0', maxWidth: '320px', width: '100%' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
							<CardContent sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center' }}>
									<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
										<Typography sx={{ hyphens: 'auto' }} component="div" variant="h6">
											{product['product_'+Constants.lang] ? product['product_'+Constants.lang] : product['title_'+Constants.lang]}
										</Typography>
										{product['producer_'+Constants.lang] ?
											<Typography variant="subtitle1" color="text.secondary" component="div">
												{product['producer_'+Constants.lang]}
											</Typography>
										: ''}
										<Typography sx={{ marginTop: 'auto' }} variant="subtitle2" color="text.secondary" component="div">
											€ {product.price}{product.quantity ? `/ ${product.quantity}` : ''}
										</Typography>
									</Box>
									<CardMedia
										component="img"
										sx={{ maxWidth: 100, maxHeight: 120, objectFit: 'contain' }}
										image={product['product_'+Constants.lang] ? Constants.cms+product.picture.path : Constants.cms+product.img.path}
										alt={product['producer_'+Constants.lang] ? product['producer_'+Constants.lang] : ''}
									/>
							</CardContent>
							<CardContent sx={{ paddingTop: '0px' }}>
								<Slide
									sx={{ padding: 0, marginLeft: '10px', width: '93%' }}
									name={product['product_'+Constants.lang] ? product['product_'+Constants.lang] : product['title_'+Constants.lang]}
									min={0}
									step={1}
									max={5}
									marks
									defaultValue={userSettings[data.name][i].value}
									onChange={product['product_'+Constants.lang] ? handleShopping : handleBreakfast}
									valueLabelDisplay="on"
									aria-labelledby="product-quantity"
								/>
							</CardContent>
						</Box>
					</Card>
				))}
			</div>
		)
	}
	else return <CircularProgress />
}

const Toggle = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
	  borderRadius: 22 / 2,
	  '&:before, &:after': {
		content: '""',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: 16,
		height: 16,
	  },
	  '&:before': {
		backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
		  theme.palette.getContrastText(theme.palette.primary.main),
		)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
		left: 12,
	  },
	  '&:after': {
		backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
		  theme.palette.getContrastText(theme.palette.primary.main),
		)}" d="M19,13H5V11H19V13Z" /></svg>')`,
		right: 12,
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxShadow: 'none',
	  width: 16,
	  height: 16,
	  margin: 2,
	},
  }));

const ToggleField = (data) => (
	<ListItem>
		<ListItemIcon>
			{data.icon}
		</ListItemIcon>
		<FormControlLabel
			control={<Toggle
				checked={userSettings[data.section] && userSettings[data.section][data.name] ? userSettings[data.section][data.name] : false}
				onChange={(event) => setUserSettings({
					...userSettings,
					[data.section]: {
						...userSettings[data.section],
						[data.name]: event.target.checked
					}
				})}
			/>}
		/>
		<ListItemText primary={[Constants[data.name][Constants.lang]]} />
	</ListItem>
)

const ToggleFieldExtended = (data) => (
	<ListItem>
		<ListItemIcon>
			{data.icon}
		</ListItemIcon>
		<FormControlLabel
			control={<Toggle
				checked={userSettings[data.section] && userSettings[data.section][data.name] && userSettings[data.section][data.name].value ? userSettings[data.section][data.name].value : false}
				onChange={(event) => setUserSettings({
					...userSettings,
					[data.section]: {
						...userSettings[data.section],
							...userSettings[data.section],
						...userSettings[data.section],
						[data.name]: {
							value: event.target.checked
						}
					}
				})}
			/>}
		/>
		<ListItemText primary={[Constants[data.name][Constants.lang]]} />
	</ListItem>
)

const ProductCard = (data) => {
	if(userSettings[data.name].length) {
		return (
			<div className='shop-products' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
				{userSettings[data.name].map((product,i) => {
					const name = {
						de: product.name,
						it: product.name_it,
						en: product.name_en
					}
					const desc = {
						de: product.desc,
						it: product.desc_it,
						en: product.desc_en
					}
					return (
						<Card key={i} sx={{ margin: '12px 0', maxWidth: '320px', width: '100%' }}>
							<CardHeader
								title={name[Constants.lang]}
								subheader={'€ '+product.price+'/'+Constants.dayLabel[Constants.lang]}
							/>
							<CardMedia
								sx={{ objectFit: 'contain' }}
								component="img"
								height="150"
								image={Constants.cms+product.image.path}
								alt={name[Constants.lang]}
							/>
							<CardContent>
								<Typography variant="body2" color="text.secondary">
									{desc[Constants.lang]}
								</Typography>
							</CardContent>
							<CardActions sx={{ flexDirection: 'column', marginBottom: '16px' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<MobileDatePicker
										label={Constants.reserveDate[Constants.lang]}
										inputFormat="dd.MM.yyyy"
										clearable
										value={date[i]}
										onChange={(value) => {
											let copy = userSettings
											for (let index = 0; index < copy[data.name].length; index++) {
												const el = copy[data.name][index];
												if(index === i) {
													el.value = value
													setUserSettings(copy)
												}
											}
											let copyDate = date
											copyDate[i] = value
											setDate([...copyDate])
										}}
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
							</CardActions>
						</Card>
					)
				})}
			</div>
		)
	}
	else return <CircularProgress />
}

let summaryArrays = ''
let summaryObjects = ''
let summaryStrings = ''
let guestInfo = ''
if (prop.guestData.bookingCode) guestInfo = `${Constants.bookingCode[Constants.lang]}: ${prop.guestData.bookingCode}<br/>
	${Constants.nameLabel[Constants.lang]}: ${prop.guestData.bookingGuest.firstName} ${prop.guestData.bookingGuest.lastName}<br/>
	${Constants.emailLabel[Constants.lang]}: ${prop.guestData.bookingGuest.email}<br/>
	${Constants.arrivalLabel[Constants.lang]}: ${new Date(prop.guestData.roomTypeReservations[0].arrival).toLocaleDateString(Constants.lang)}`
else guestInfo = `${Constants.nameLabel[Constants.lang]}: ${prop.guestData.bookingGuest.lastName}`

let orderArr = [];
const createSummary = () => {
	Object.entries(userSettings).forEach(([key, value]) => {
		const section = key
		// if value is array
		if(Array.isArray(value)) {
			let counter = 0
			let name
			value.forEach(el => {
				if(el.value && el.value !== 0) {		
					if(counter === 0) summaryArrays += `
						<br/>
						<div style="text-decoration: underline">${Constants[section][Constants.lang]}</div>`
					// name correction
					if(el.name) {
						name = {
							de: el.name,
							it: el.name_it,
							en: el.name_en,
						}
					}
					if(el.product_de) {
						name = {
							de: el.product_de,
							it: el.product_it,
							en: el.product_en,
						}
					}
					if(el.title_de) {
						name = {
							de: el.title_de,
							it: el.title_it,
							en: el.title_en,
						}
					}
					if(typeof el.value === 'number') {
						summaryArrays += `<span style="font-weight: bold">${name[Constants.lang]}</span>: ${el.value}<br/>`;
						orderArr.push({
							name: name.de,
							quantity: el.value,
							extraId: el.extraId,
							erbbrot: el.erbbrot || null,
							breadOrder: el.breadOrder || null
						})
					}
					else summaryArrays += `<span style="font-weight: bold">${name[Constants.lang]}</span>: ${new Date(el.value).toLocaleDateString(Constants.lang)}<br/>`
					counter++
				}
			});
		}
		// if value is string
		else if(typeof(value) === 'string' && value !== '') summaryStrings += `<span style="font-weight: bold">${Constants[section][Constants.lang]}</span>: ${value}<br/>`
		// if value is object
		else {
			Object.entries(value).forEach(([key, value]) => {
				const name = key
				if(value === true || value.value === true) {
					summaryObjects += `<span style="font-weight: bold">${Constants[name][Constants.lang]}: </span>`
					if(Object.entries(value).length) {
						Object.entries(value).forEach(([key, value]) => {
							if(key === 'value') summaryObjects += Constants.selected[Constants.lang]+'<br/>'
							else if(key === 'date') summaryObjects += `&nbsp;&nbsp;-  ${Constants[key][Constants.lang]}: ${new Date(value).toLocaleDateString(Constants.lang)}<br/>`
							else if(key === 'price') summaryObjects += `&nbsp;&nbsp;-  ${Constants[key][Constants.lang]}: ${value},00 €<br/>`
							else summaryObjects += `&nbsp;&nbsp;-  ${Constants[key][Constants.lang]}: ${value}<br/>`
						})
					}
					else summaryObjects += Constants.selected[Constants.lang]+'<br/>'
				}
			})
		}
	});
}

const Summary = () => {
	createSummary()
	return (
		<div className='summary'>
			<Typography variant="body2" component="div">
				<div dangerouslySetInnerHTML={{__html: summaryArrays}} />
				{summaryArrays ? <br/> : ''}
				<div dangerouslySetInnerHTML={{__html: summaryObjects}} />
				{summaryObjects ? <br/> : ''}
				<div dangerouslySetInnerHTML={{__html: summaryStrings}} />
			</Typography>
		</div>
	)
}

let steps = [
  {
	label: Constants.ecar[Constants.lang],
	description: Constants.chargingStationDesc[Constants.lang],
	input: <ToggleField section="ecar" name="chargingStation" icon={<EvStationRoundedIcon />} />
  },
  {
	label: Constants.apartmentConfig[Constants.lang],
	input:
		<FormGroup>
			<ToggleField section="apartmentConfig" name="analcoholic" icon={<LocalBarRoundedIcon />} />
			<ToggleField section="apartmentConfig" name="allergic" icon={<KingBedRoundedIcon />} />
			<ToggleField section="apartmentConfig" name="sofaBed" icon={<WeekendRoundedIcon />} />
			<ToggleField section="apartmentConfig" name="babyBed" icon={<CribRoundedIcon />} />
			<ToggleField section="apartmentConfig" name="childChair" icon={<EventSeatRoundedIcon />} />
		</FormGroup>
  },
  {
	label: Constants.shoppingService[Constants.lang],
	description: Constants.shoppingServiceDesc[Constants.lang],
	input: <ShopProducts name="shoppingService" />
  },
  {
	label: Constants.specialEvent[Constants.lang],
	description: Constants.specialEventDesc[Constants.lang],
	input:
		<FormGroup>
			<ToggleFieldExtended section="specialEvent" name="bouquet" icon={<FilterVintageRoundedIcon />} />
			{userSettings.specialEvent && userSettings.specialEvent.bouquet && userSettings.specialEvent.bouquet.value ?
				<Box component="form"
					sx={{
					'& .MuiTextField-root': { m: 1, width: '25ch' },
					}}
					noValidate
					autoComplete="off"
				>
					<TextField
						label={Constants.reason[Constants.lang]}
						variant="outlined"
						value={userSettings.specialEvent && userSettings.specialEvent.bouquet && userSettings.specialEvent.bouquet.reason ? userSettings.specialEvent.bouquet.reason : ''}
						onChange={(event, value) => setUserSettings({
							...userSettings,
							specialEvent: {
								...userSettings.specialEvent,
								bouquet: {
									...userSettings.specialEvent.bouquet,
									reason: event.target.value
								}
							}
						})}
						required
						multiline={true}
						rows={3}
					/>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<MobileDatePicker
							label={Constants.date[Constants.lang]}
							inputFormat="dd.MM.yyyy"
							clearable
							value={userSettings.specialEvent && userSettings.specialEvent.bouquet && userSettings.specialEvent.bouquet.date ? userSettings.specialEvent.bouquet.date : null}
							onChange={(value) => setUserSettings({
								...userSettings,
								specialEvent: {
									...userSettings.specialEvent,
									bouquet: {
										...userSettings.specialEvent.bouquet,
										date: value
									}
								}
							})}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
					<TextField
						label={Constants.price[Constants.lang]}
						value={userSettings.specialEvent && userSettings.specialEvent.bouquet && userSettings.specialEvent.bouquet.price ? userSettings.specialEvent.bouquet.price : ''}
						onChange={(event) => setUserSettings({
							...userSettings,
							specialEvent: {
								...userSettings.specialEvent,
								bouquet: {
									...userSettings.specialEvent.bouquet,
									price: Number(event.target.value)
								}
							}
						})}
						InputProps={{
							startAdornment: <InputAdornment position="start">€</InputAdornment>,
							inputProps: {min: 20}
						}}
						type="number"
					/>
				</Box>
				: ''
			}
			<ToggleFieldExtended section="specialEvent" name="cake" icon={<CakeRoundedIcon />} />
			{userSettings.specialEvent && userSettings.specialEvent.cake && userSettings.specialEvent.cake.value ?
				<Box component="form"
					sx={{
					'& .MuiTextField-root': { m: 1, width: '25ch' },
					}}
					noValidate
					autoComplete="off"
				>
					<TextField
						label={Constants.reason[Constants.lang]}
						variant="outlined"
						value={userSettings.specialEvent && userSettings.specialEvent.cake && userSettings.specialEvent.cake.reason ? userSettings.specialEvent.cake.reason : ''}
						onChange={(event, value) => setUserSettings({
							...userSettings,
							specialEvent: {
								...userSettings.specialEvent,
								cake: {
									...userSettings.specialEvent.cake,
									reason: event.target.value
								}
							}
						})}
						required
						multiline={true}
						rows={3}
					/>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<MobileDatePicker
							label={Constants.date[Constants.lang]}
							inputFormat="dd.MM.yyyy"
							clearable
							value={userSettings.specialEvent && userSettings.specialEvent.cake && userSettings.specialEvent.cake.date ? userSettings.specialEvent.cake.date : null}
							onChange={(value) => setUserSettings({
								...userSettings,
								specialEvent: {
									...userSettings.specialEvent,
									cake: {
										...userSettings.specialEvent.cake,
										date: value
									}
								}
							})}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
					<TextField
						label={Constants.price[Constants.lang]}
						value={userSettings.specialEvent && userSettings.specialEvent.cake && userSettings.specialEvent.cake.price ? userSettings.specialEvent.cake.price : ''}
						onChange={(event) => setUserSettings({
							...userSettings,
							specialEvent: {
								...userSettings.specialEvent,
								cake: {
									...userSettings.specialEvent.cake,
									price: Number(event.target.value)
								}
							}
						})}
						InputProps={{
							startAdornment: <InputAdornment position="start">€</InputAdornment>,
							inputProps: {min: 20}
						}}
						type="number"
					/>
				</Box>
				: ''
			}
		</FormGroup>
  },
  {
	label: Constants.breakfastService[Constants.lang],
	description: new Date(prop.guestData.roomTypeReservations[0].arrival).getDay() === 6 ? Constants.breakfastServiceNotPossible[Constants.lang] : Constants.breakfastServiceDesc[Constants.lang],
	input: <ShopProducts name="breakfastService" />
  },
  {
	label: Constants.dog[Constants.lang],
	input: <ToggleField section="dog" name="dogSitter" icon={< PetsRoundedIcon />} />
  },
  {
	label: Constants.rentalService[Constants.lang],
	description: Constants.rentalServiceDesc[Constants.lang],
	input: <ProductCard name="rentalService" icon={<ElectricBikeIcon />} />
  },
  {
	label: Constants.misc[Constants.lang],
	description: Constants.miscDesc[Constants.lang],
	input: <TextField
		sx={{ margin: '16px 0' }}
		fullWidth
		label={Constants.miscInput[Constants.lang]}
		multiline={true}
		rows={5}
		value={userSettings.misc ? userSettings.misc : ''}
		onChange={(event) => setUserSettings({...userSettings, misc: event.target.value})}
	/>
  },
  {
	label: Constants.summaryLabel[Constants.lang],
	input: <Summary />
  },
];

// remove breakfastService if arrival is saturday
// if(prop.guestData.roomTypeReservations && new Date(prop.guestData.roomTypeReservations[0].arrival).getDay() === 6) {
// 	let arr = []
// 	for (let index = 0; index < steps.length; index++) {
// 		const el = steps[index];
// 		if(el.label !== Constants.breakfastService[Constants.lang]) arr.push(el)
// 	}
// 	steps = arr
// }

	const sendMail = async () => {
		setLoader(true)
		let summary = `${guestInfo}<br/>${summaryArrays}<br/>${summaryObjects}<br/>${summaryStrings}`;
		let todayDate = new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear();
		// send mail
		await fetch(Constants.baseUrl+'/php/fetchApi.php', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				url: '/api/collections/save/econcierge',
				content: {
					data: {
						date: todayDate,
						reservationId: prop.guestData.bookingCode,
						econcierge: summary,
						order: orderArr,
						arrival: new Date(prop.guestData.roomTypeReservations[0].arrival).toISOString(),
						departure: new Date(prop.guestData.roomTypeReservations[0].departure).toISOString()
					}
				}
			})
		})
		.then(res => res.json())
		.then(async() => {
			await fetch(Constants.baseUrl+'/php/mailer.php', {
				method: 'post',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					subject: 'E-Concierge',
					guestmail: prop.guestData.bookingGuest.email,
					content: summary
				})
			})
			.then(() => {
				setLoader(false)
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			})
			.catch(error => {
				console.error(error);
			})
		})
		.catch(error => {
			console.error(error);
		})
	}

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (index) => {
	  if(index === steps.length -2) {
		createSummary()
		if(!summaryStrings && !summaryArrays && !summaryObjects) setSubmit(false)
		else setSubmit(true)
	  }
	  if(index === steps.length - 1) {
		  sendMail()
	  }
	  else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
	setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
	<Box sx={{ maxWidth: 700, margin: '0 auto' }}>
	  <Stepper activeStep={activeStep} orientation="vertical">
		{steps.map((step, index) => (
			<Step key={step.label}>
				<StepLabel>
					{step.label}
				</StepLabel>
				<StepContent>
					{step.description ? <Typography>{step.description}</Typography> : ''}
					{step.input ? step.input : ''}
				<Box sx={{ mb: 2 }}>
					<div>
					<Button
						variant="contained"
						onClick={() => handleNext(index)}
						sx={{ mt: 1, mr: 1 }}
						disabled={index === steps.length - 1 && !submit ? true : false}
					>
						{index === steps.length - 1
							? loader ? <CircularProgress sx={{ color: 'white' }} /> : Constants.submitLabel[Constants.lang]
							: Constants.continueLabel[Constants.lang]
						}
					</Button>
					<Button
						disabled={index === 0}
						onClick={handleBack}
						sx={{ mt: 1, mr: 1 }}
					>
						{Constants.backLabel[Constants.lang]}
					</Button>
					</div>
				</Box>
				</StepContent>
			</Step>
		))}
	  </Stepper>
	  {activeStep === steps.length && (
		<Paper square elevation={0} sx={{ p: 3 }}>
			<CheckCircleOutlineRoundedIcon sx={{ fontSize: '120px', marginBottom: '16px' }} color='success' />
			<div dangerouslySetInnerHTML={{__html: Constants.complete[Constants.lang]}} />
		</Paper>
	  )}
	</Box>
  );
}